import React from 'react';
import CTA, { CTAButton } from '../components/CTA';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const WhyPage = ({ data }) => {
  return (
    <Layout headerClass="md:bg-light-blue-50">
      <SEO title="Gør salg overskueligt" />
      <div className="bg-light-blue-50 w-full pt-36 pb-28">
        <div className="container">
          <h1 className="heading-sm text-center">Hvorfor Sails?</h1>
          <h2 className="sub-lg font-bold text-center mt-3">
            Vi gør salgsprocessen nemmere med en ny filosofi
          </h2>
          <p className="sub-sm mx-auto max-w-2xl text-center mt-8">
            Salg er den vigtigste opgave i alle virksomheder. Sails gør
            salgsprocessen nemmere og mere overskuelig med en ny tilgang.
          </p>
          <div className="block mx-auto text-center mt-8">
            <CTAButton />
          </div>
        </div>
      </div>

      <div className="container max-w-3xl py-28 grid grid-cols-1 gap-y-16">
        <div>
          <h3 className="sub-lg font-bold mb-6">
            Mere overblik – mere salg – mere værdi
          </h3>
          <p className="mb-2">
            Sails er et dedikeret salgsystem der hjælper dig til at gøre din
            salgsproces så nem og overskuelig som mulig. Vi er ikke et
            økonomisystem, og derfor kan vi holde vores fokus 100% på
            salgsprocessen. I stedet integrererer vi med (næsten) alle
            økonomisystemer og sørger for at dine faktura bliver bogført nemt,
            enkelt og automatisk.
          </p>
          <p className="mb-2">Sails gør din salgsproces enkel.</p>
        </div>

        <div>
          <h3 className="sub-lg font-bold mb-6">
            Salg er en lappeløsning i dit økonomisystem
          </h3>
          <p className="mb-2">
            Moderne økonomisystemer er proppet til randen med features, men
            fokus er på bogføring og økonomi. Ofte er styring af salgsprocessen
            og indkøb et tillægsmodul og en lappeløsning, der begrænser din
            salgsproces mere end det gavner den.
          </p>
          <p className="mb-2">
            Det der mangler, er en ny frisk tilgang som er født ud af et andet
            perspektiv. En helt ny filosofi med udgangspunkt i salgsprocessen.
            Den har Sails. Med en stjerneklar vision, fokus på salg- og
            indkøbsprocessen og et nyskabende design hjælper vi dig med at gøre
            salgsprocessen hurtigere og lettere.
          </p>
          <p className="mb-2">
            Du sælger hver dag - software skal ikke stå i vejen for dig. Det
            skal i stedet hjælpe dig med at opnå dine mål.
          </p>
          <p className="mb-2">Det hjælper Sails dig med.</p>
          <p className="mb-2">Sails gør din salgsproces enkel.</p>
        </div>

        <div>
          <h3 className="sub-lg font-bold mb-6">
            Regnearket er blevet en flaskehals
          </h3>
          <p className="mb-2">
            I mange år, har regneark været motoren for de vigtigste processer i
            virksomheder, og opfattelsen er at de mange regneark har løst vores
            problemer. Men det har de ikke. Idag er regneark blevet en
            flaskehals, mere uoverskuelig end nogensinde og mere frustrerende
            end nogensinde. Og det bliver værre endnu.
          </p>
          <p className="mb-2">
            Dine regneark er fuld af værdifuld data, som du ikke har en jordisk
            change for at finde eller dele med andre, når størrelsen vokser. Du
            kan ikke samarbejde i dit regneark og du er altid låst til dine
            tabeller, som typisk er opsat af struktur-fascisten på dit team, der
            sørger for at ingen andre kan vedligeholde regnearket. Det er
            dårligt for din salgsprocess.
          </p>
          <p className="mb-2">
            Det er tid til at kæmpe imod. Tid til at tage kontrol over din
            salgsproces, og ikke være begrænset af tabeller og komplekse
            beregninger. Det er på tide at stå sammen - så alle kan være med og
            alle kan opnå dine salgsmål.
          </p>
          <p className="mb-2">Det er Sails.</p>
        </div>
      </div>

      <div className="bg-orange-50 text-orange-900 py-28">
        <div className="container text-center max-w-4xl mb-16">
          <h2 className="heading-sm">Vores filosofi</h2>
          <p>
            Mens resten af industrien er gået i stå over komplekse,
            uoverskuelige løsninger med alt for meget rod, bygger vi Sails på en
            række fundamentale idealer. Her er hvad vi tror på, hvad der betyder
            noget for os. Her er Sails filosofien.
          </p>
        </div>

        <div className="container max-w-3xl">
          <h3 className="sub-lg font-bold mb-6 mt-16">
            Mindre software - mere værdi
          </h3>
          <p>
            Software hjælper med at løse problemer. Men for meget software
            skaber nye problemer. Det handler om at løse det som giver mening og
            det som skaber værdi. Sails er small-tech i en verden domineret af
            large-tech. Vi holder os fast på at være præcise og
            løsningsorienterede - uden at sætte det hele over styr med
            overdimensioneret software.
          </p>

          <h3 className="sub-lg font-bold mb-6 mt-16">
            Vi lappeløser ikke problemer
          </h3>
          <p>
            Vi tror ikke på lappe-løsninger, men bygger i stedet arbejdsgange
            der giver mening. Derfor er vi også kritiske overfor nye features -
            indtil de har bevist deres værdi. Det sikrer at du ikke får et
            ubrugligt stykke software der er fyldt til randen med tilfældige
            features.
          </p>

          <h3 className="sub-lg font-bold mb-6 mt-16">
            Standarder over konfigurationer
          </h3>
          <p>
            Du burde ikke skulle igennem hundredevis af forskellige
            indstillinger og skærme for at konfigurere dit salgssystem så det
            passer til dit basale behov. Sails bruger en mere menneskelig
            tilgang med indbyggede arbejdsgange, simple indstillinger, naturlig
            navigation og standardiserede skabeloner.
          </p>

          <h3 className="sub-lg font-bold mb-6 mt-16">
            Mindre kompleksitet, mere fleksibilitet
          </h3>
          <p>
            Komplekse salgssystemer med avanceret email-markedsføring, avanceret
            website-tracking, hundrede forskellige salgs-pipelines. De mener
            alle at de gør arbejdet lettere og mere fleksibelt. Det er vi ikke
            enige i. Det bliver simpelthen for svært at bruge. Og det som det
            skal gøre bedst, at styre salgsprocessen, det bliver de
            ligepludselig ikke så gode til. Vi tror i stedet på at mindre
            kompleksitet betyder mere fleksibilitet.
          </p>

          <h3 className="sub-lg font-bold mb-6 mt-16">Du ejer din data</h3>
          <p>
            Vi tror på åbenhed. Den data som er i Sails, er ikke vores. Den er
            til låns, så vi kan gøre livet lidt lettere for dig. Det er dig der
            ejer din data, og du vil altid kunne få den ud. Det skal være muligt
            at vokse og ændre sig. Derfor er det også vigtigt at du kan få den
            data der tilhører dig.
          </p>
        </div>
      </div>

      <CTA />
    </Layout>
  );
};

export default WhyPage;
